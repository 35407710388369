<template>
    <ts-panel>
        <ts-panel-wrapper>
            <h1 class="tw-mb-0">{{ $t("company.profile") }}</h1>
            <div class="mb-3 tw-mt-3">
                <label class="mb-1 required">{{ $t("company.nameEn") }}</label>
                <input
                    v-model.trim="model.company_name_en"
                    type="text"
                    class="form-control"
                    :placeholder="$t('company.nameEn')"
                    :class="{ 'is-invalid': errors.has('company_name_en') }"
                    ref="company_name_en"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('company_name_en')"
                >
                    {{ errors.first("company_name_en") }}
                </div>
            </div>
            <div class="mb-3">
                <label class="mb-1 required">{{ $t("company.nameKh") }}</label>
                <input
                    v-model.trim="model.company_name_kh"
                    type="text"
                    class="form-control"
                    :placeholder="$t('company.nameKh')"
                    :class="{ 'is-invalid': errors.has('company_name_kh') }"
                    ref="company_name_kh"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('company_name_kh')"
                >
                    {{ errors.first("company_name_kh") }}
                </div>
            </div>
            <div>
                <div class="row gx-3 mb-3">
                    <div class="col-md-6 mb-1 mb-md-0">
                        <label class="mb-1 required">{{
                            $t("company.prefixCode")
                        }}</label>
                        <input
                            v-model.trim="model.prefix_code"
                            type="prefix_code"
                            class="form-control"
                            :placeholder="$t('company.prefixCode')"
                            :class="{ 'is-invalid': errors.has('prefix_code') }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('prefix_code')"
                        >
                            {{ errors.first("prefix_code") }}
                        </div>
                    </div>
                    <div class="col-md-6 mb-1 mb-md-0">
                        <label class="mb-1">{{
                            $t("company.phoneNumber")
                        }}</label>
                        <input
                            v-model.trim="model.company_phone"
                            type="company_phone"
                            class="form-control"
                            :placeholder="$t('company.phoneNumber')"
                            :class="{
                                'is-invalid': errors.has('company_phone')
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('company_phone')"
                        >
                            {{ errors.first("company_phone") }}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="row gx-3 mb-3">
                    <div class="col-md-6 mb-1 mb-md-0">
                        <label class="mb-1">{{ $t("company.email") }}</label>
                        <input
                            v-model.trim="model.company_email"
                            type="company_email"
                            class="form-control"
                            :placeholder="$t('company.email')"
                            :class="{
                                'is-invalid': errors.has('company_email')
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('company_email')"
                        >
                            {{ errors.first("company_email") }}
                        </div>
                    </div>
                    <div class="col-md-6 mb-1 mb-md-0">
                        <label class="mb-1">{{ $t("company.website") }}</label>
                        <input
                            v-model.trim="model.company_website"
                            type="company_website"
                            class="form-control"
                            :placeholder="$t('company.website')"
                            :class="{
                                'is-invalid': errors.has('company_website')
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('company_website')"
                        >
                            {{ errors.first("company_website") }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label class="mb-1">{{ $t("company.address") }}</label>
                <textarea
                    v-model.trim="model.company_address"
                    type="text"
                    class="form-control"
                    :placeholder="$t('company.address')"
                    :class="{ 'is-invalid': errors.has('company_address') }"
                    rows="3"
                ></textarea>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('company_address')"
                >
                    {{ errors.first("company_address") }}
                </div>
            </div>
            <div>
                <div id="img-frame" class="text-center">
                    <label for="logo_file" id="label_logo_file">
                        <img :src="logo_file_src" alt="" />
                    </label>
                    <input
                        type="file"
                        id="logo_file"
                        ref="logo_file"
                        accept="image/*"
                        @change="onFileSelected"
                    />
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
import { mapState } from "vuex";

export default {
    name: "companyProfile",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            logo_file_src: require("@/assets/default-logo.jpg"),
            model: {}
        };
    },
    computed: {
        ...mapState("corporate/company", ["edit_data"]),
        isEdit() {
            return !isEmpty(this.edit_data);
        },
        employees() {
            return this.$store.state.corporate.company.employees;
        }
    },
    mounted() {
        this.$refs.company_name_en.focus();
    },
    created() {
        this.model = this.value;
    },
    methods: {
        uploadLogo() {
            this.file = this.$refs.logo_file.files[0]
                ? this.$refs.logo_file.files[0]
                : null;
            if (this.file) {
                let formData = new FormData();
                formData.append("companyPhoto", this.file);
                return this.$store.dispatch(
                    "corporate/company/uploadLogo",
                    formData
                );
            }

            return "";
        },
        onFileSelected() {
            const file = this.$refs.logo_file.files[0];
            let reader = new FileReader();
            reader.addEventListener(
                "load",
                function() {
                    this.logo_file_src = reader.result;
                }.bind(this),
                false
            );
            if (file) {
                if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
                    reader.readAsDataURL(file);
                } else {
                    this.logo_file_src = require("@/assets/default-logo.jpg");

                    this.$Message.error("invalid file type!");
                    this.$refs.logo_file.value = "";
                }
            }
        },
        loadLogoFileSource() {
            if (this.edit_data.company_logo != null) {
                this.logo_file_src = this.edit_data.company_logo;
            }
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
<style>
#logo_file {
    display: none;
}
#img-frame img {
    object-fit: contain;
    width: 200px;
    height: 180px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, .1); */
}
#label_logo_file {
    cursor: pointer;
}
</style>
